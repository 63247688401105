<template>
  <alumnosList />
</template>

<script>
import alumnosList from './components/AlumnosList.vue'

export default {
  components: {
    alumnosList,
  },
}
</script>
